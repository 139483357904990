
import GET_LEGAL_ADVICE_BY_SAFEWILL_LEGAL_TERMS_QUERY from '~/graphql/queries/GetLegalAdviceBySafewillLegalTerms';

import { craft, transition } from '~/mixins';

export default {
  name: 'PageLegalAdviceBySafewillLegalTerms',
  mixins: [
    craft({
      query: GET_LEGAL_ADVICE_BY_SAFEWILL_LEGAL_TERMS_QUERY,
      isLocalizable: false,
    }),
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
